<!--
 * @Author: 肖槿
 * @Date: 2020-06-12 15:46:37
 * @Description: 账户管理
 * @LastEditors: 矢车
 * @LastEditTime: 2020-09-18 17:42:58
 * @FilePath: \s2b-admin\src\pages\admin\shop\account-management.vue
-->
<template>
  <div id="account-management">
    <a-tabs type="card" @change="changeTabs">
      <a-tab-pane tab="账户列表" key="1">
        <account-management-search @success="searchHandler" />
        <a-table :scroll="{ y: tableScrollY }" :columns="AccountTable" :dataSource="list" :loading="listLoading" :locale="{ emptyText: '暂无信息' }" :pagination="tablePage" @change="paginationHandler">
          <template slot="action" slot-scope="row">
            <a-button size="small" @click="confirmDetail(row)" class="mr10">账户详情</a-button>
            <a-button v-if="row.to_audit_deposit" type="primary" size="small" @click="confirmMarginClick(row)">确认保证金</a-button>
            <a-button v-if="row.pay_deposit_status == 2" size="small" @click="checkDetail(row)" v-auth="'shop_account_manage_bond_detail'">保证金详情</a-button>
          </template>
        </a-table>
        <a-modal v-if="visible" :visible="visible" :keyboard="false" :maskClosable="false" title="确认保证金" @ok="handleOk" @cancel="visible=false">
          <div class="model-line-block">
            <span>商家名称：</span>
            <p>{{modelData.merchant_name}}</p>
          </div>
          <div class="model-line-block">
            <span>保证金金额：</span>
            <p>{{modelData.deposit_history_info.amount}}</p>
          </div>
          <div class="model-line-block" id="cert-image-wrap">
            <span>缴纳凭证：</span>
            <img :data-original="modelData.deposit_history_info.transfer_voucher" :src="modelData.deposit_history_info.transfer_voucher+ '?x-oss-process=image/resize,m_fill,h_140,w_140'" class="transfe-voucher-img" />
          </div>
        </a-modal>
        <template>
          <a-drawer :destroyOnClose="true" title="账户详情" :width="1200" :visible="accountDetail.isShow" :body-style="{ paddingBottom: '80px' }" @close="onDetailCloses">
            <accountManagementDetail @showSpin="isShowSpin" :propsRecord="record"></accountManagementDetail>
          </a-drawer>
        </template>
        <a-drawer :destroyOnClose="true" title="保证金详情" :width="550" :visible="accountDetaiDrawer.isShow" :body-style="{ paddingBottom: '80px' }" @close="onDetailClose">
          <inquireDetail :row-data="checkedRow" />
        </a-drawer>
      </a-tab-pane>
      <a-tab-pane :forceRender="true" v-if="premissTab2" key="2" v-auth="'shop_account_manage_wait_examine_tab'">
        <span slot="tab">
          提现待审核
          <span :class="markPremissTab2?'marksPremissTab':''"></span>
        </span>
        <withdrawStayAudit ref="withdrawStayAudit" @changeMarks="changeMarksCircle"></withdrawStayAudit>
      </a-tab-pane>
      <a-tab-pane :forceRender="true" v-if="premissTab3" key="3" v-auth="'shop_account_manage_wait_payment_tab'">
        <span slot="tab">
          提现待打款
          <span :class="markPremissTab3?'marksPremissTab':''"></span>
        </span>
        <withdrawStayPay ref="withdrawStayPay" @changeMarks="changeMarksCircle3"></withdrawStayPay>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import {
  accountManagementSearch,
  accountManagementDetail,
  inquireDetail,
  withdrawStayPay,
  withdrawStayAudit,
} from "@/components/admin/shop";
import { AccountTable } from "@/const/shop";
import { getAccountList, confirmMargin } from "@/service/shop";
import { permiss } from "@/utils/shop";
import Viewer from "viewerjs";
export default {
  name: "AccountManagement",
  components: {
    accountManagementSearch,
    accountManagementDetail,
    withdrawStayPay,
    withdrawStayAudit,
    inquireDetail,
  },
  data() {
    return {
      tableScrollY: 600,
      premissTab2: true,
      markPremissTab2: false, //  tab2显示圆点
      markPremissTab3: false, //  tab3显示圆点
      premissTab3: true,
      accountDetaiDrawer: {
        isShow: false,
      },
      visible: false,
      listLoading: false,
      record: {},
      AccountTable,
      queryData: {},
      pagination: {
        page: 1,
        page_size: 10,
        total: 0,
      },
      list: [],
      modelData: {},
      accountDetail: {
        isShow: false,
      },
      checkedRow: {},
    };
  },
  created() {
    var windowHeight = document.documentElement.clientHeight || 1000;
    this.tableScrollY = windowHeight - 375;
  },
  computed: {
    tablePage() {
      return {
        current: this.pagination.page,
        total: this.pagination.total,
        pageSize: this.pagination.page_size,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        showTotal: (total) =>
          `共 ${total} 条记录 第 ${this.pagination.page}/${Math.ceil(
            total / this.pagination.page_size
          )} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
      };
    },
  },
  async mounted() {
    // 用v-auth控制权限，tab栏还是会有，只是把里面的内容移除了，所以自己封装了一个方法，用v-if来直接判断
    this.premissTab2 = permiss("shop_account_manage_wait_examine_tab");
    this.premissTab3 = permiss("shop_account_manage_wait_payment_tab");
    this.getDataList();
  },
  methods: {
    isShowSpin(isBool) {
      this.accountDetail.isShow = isBool;
    },
    /**
     * @Author: 矢车
     * @Date: 2020-07-24 10:51:13
     * @Description: 点击账户详情
     */
    confirmDetail(row) {
      this.record = row;
      this.accountDetail.isShow = true;
    },

    /**
     * @Author: 矢车
     * @Date: 2020-07-24 10:57:17
     * @Description: 关闭详情drawer
     */
    onDetailCloses() {
      this.accountDetail.isShow = false;
    },
    /**
     * @Author: 矢车
     * @Date: 2020-08-09 16:57:52
     * @Description: 切换 tab 的回调方法
     */
    changeTabs(activeKey) {
      if (activeKey == 2) {
        this.$refs.withdrawStayAudit.getList();
      } else if (activeKey == 3) {
        this.$refs.withdrawStayPay.getList();
      }
    },

    /**
     * @Author: 矢车
     * @Date: 2020-08-09 16:44:43
     * @Description: 子组件传值
     */
    changeMarksCircle(isBool) {
      this.markPremissTab2 = isBool;
      this.$refs.withdrawStayPay.getList();
    },

    /**
     * @Author: 矢车
     * @Date: 2020-08-09 16:48:43
     * @Description: 子组件传值
     */
    changeMarksCircle3(isBool) {
      this.markPremissTab3 = isBool;
    },

    /**
     * 分页操作
     * @author 棠棣
     * @since  2020-06-28 10:40:00
     */
    paginationHandler(params) {
      this.pagination.page = params.current;
      this.pagination.page_size = params.pageSize;
      this.getDataList();
    },

    /**
     * 搜索操作
     * @author 棠棣
     * @since  2020-06-24 14:51:00
     */
    searchHandler(data) {
      this.queryData = data;
      this.pagination.page = 1;
      this.getDataList();
    },

    /**
     * 确认保证金操作
     * @author 棠棣
     * @since  2020-06-24 15:34:00
     */
    handleOk() {
      this.$confirm({
        title: "确认已收到保证金吗?",
        onOk: async () => {
          const params = {
            id: this.modelData.deposit_history_info.id,
            audit_status: 2,
          };
          const { res } = await confirmMargin(params);
          if (res.success) {
            this.$message.success("操作成功");
            this.visible = false;
            this.getDataList();
          }
        },
        onCancel() {},
      });
    },
    confirmMarginClick(row) {
      let data = JSON.parse(JSON.stringify(row));
      data.deposit_history_info.amount = (
        data.deposit_history_info.amount / 100
      ).toFixed(2);
      this.modelData = data;
      this.visible = true;
      this.$nextTick(() => {
        // 初始化 相册功能
        new Viewer(document.getElementById("cert-image-wrap"), {
          url: "data-original",
        });
      });
    },
    // 查看明细
    checkDetail(row) {
      this.accountDetaiDrawer.isShow = true;
      this.checkedRow = JSON.parse(JSON.stringify(row));
    },
    onDetailClose() {
      this.accountDetaiDrawer.isShow = false;
    },
    /**
     * 对象参数空值过滤
     * @author 棠棣
     * @since  2020-06-24 14:35:00
     */
    filterParams(params) {
      let newParams = {};
      for (let i in params) {
        if (params[i]) {
          newParams[i] = params[i];
        }
      }
      return newParams;
    },

    /**
     * 获取账号列表
     * @author 棠棣
     * @since  2020-06-24 14:36:00
     */
    async getDataList() {
      this.listLoading = true;
      const _obj = Object.assign({}, this.pagination, this.queryData);
      delete _obj.total;
      const { res } = await getAccountList(_obj);
      this.listLoading = false;
      this.pagination.total = res.data.total;

      for (let i = 0; i < res.data.account_list.length; i++) {
        res.data.account_list[i].deposit_money = (
          res.data.account_list[i].deposit_money / 100
        ).toFixed(2);
      }

      this.list =
        res.data.account_list.map((item) => {
          return {
            ...item,
            account_balanceFormat: item.account_balance / 100,
            unreconciled_amountFormat: item.unreconciled_amount / 100,
          };
        }) || [];
      this.listLoading = false;
    },
  },
};
</script>

<style lang="less" scoped>
#account-management {
  position: relative;
}
.model-line-block {
  display: flex;
  span {
    display: inline-block;
    min-width: 90px;
  }
  .transfe-voucher-img {
    width: 100px;
    cursor: pointer;
  }
}
</style>
<style scoped>
.marksPremissTab {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #e83741;
  position: absolute;
  margin-left: 2px;
  margin-top: 6px;
  z-index: 1;
}
</style>


